import { createAction, props } from '@ngrx/store';
import { Category } from '@src/app/shared/models/interfaces/category';

export const getAllCategories = createAction('[App] Get All Categories');

export const getAllCategoriesForce = createAction(
  '[App] Get All Categories Force'
);

export const getAllCategoriesSuccess = createAction(
  '[App] Get all Categories with success',
  props<{
    categories: Category[];
    categoriesWithArticles: Category[];
  }>()
);

export const getAllCategoriesFailure = createAction(
  '[App] Get all Categories with failure',
  props<{ error: string }>()
);
